import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalView: false,
        foto: {
            foto_id: '',
            foto_nama: '',
            foto_ket: '',
            foto_foto: '',
            foto_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalView = value
        },
        viewModal(state, value) {
            state.foto = value
        }
    }
})
