import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalView: false,
        sop: {
            sop_pengaduan_id: '',
            sop_pengaduan_nama: '',
            sop_pengaduan_ket: '',
            sop_pengaduan_foto: '',
            sop_pengaduan_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalView = value
        },
        viewModal(state, value) {
            state.sop = value
        }
    }
})
